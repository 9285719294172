import {PURCHASE_RAILCARD_LINK} from "../../config"
import {getFRTParameters} from "./getFRTParameters"

export const generatePurchaseRailcardURL = (defaultCampaignId?:string, defaultSourceId?:string, defaultMediumId?:string) => {
    const baseURL = PURCHASE_RAILCARD_LINK
    const queryParameters = getFRTParameters(defaultCampaignId, defaultSourceId, defaultMediumId)
    const queryString = queryParameters ? `?${encodeURI(queryParameters)}` : ''

    return `${baseURL}${queryString}`
}
