import React, { FC } from 'react'
import {
  WarningAlertText,
  IndustrialActionContainer,
  IndustrialActionHeaderText,
  IndustrialActionParagraph,
  IndustrialActionSection,
  WarningInfobox,
  WarningInfoboxContainer,
  DividerWrap,
  NothingToSeeHeader,
  NothingToSeeParagraph,
  NothingToSeeLink,
  NothingToSeeIcon,
  NothingToSeeContainer,
  WarningBodyText,
  RefundTicketsContainer,
  RefundTicketsTitle,
  RefundTicketsBody,
  RefundTicketsButton,
} from './IndustrialAction.styles'
import { Divider } from '../../components/Divider/Divider'
import { IndustrialActionProps, StrikeEntry } from './types'
import { HelpFooterBlock } from '../../components/HelpFooterBlock/HelpFooterBlock'
import noTrainIcon from '../../img/icon-brand-no-train.svg'
import { PATHS } from '../../util/helpers/paths'

enum EntryAriaPrefixPlaceholder {
  Index = '{{indexEntry}}',
  Total = '{{totalEntries}}',
  Date = '{{dateEntry}}',
}
// This array should contain any action. Follow the pattern of this object when adding any new action.
const currentAction: IndustrialActionProps[] = [
  {
    title: 'Service disruptions',
    bodyTop: [
      'Digital signalling testing will significantly impact services on the East Coast Main Line from 20:00 Saturday 24 August to 08:00 Monday 26 August.',
      'Journeys to and from London will take considerably longer than normal, and you are advised to check revised journey details in the app before you travel.',
      'Disruptions will include cancellations, reduced services and rail replacement services.',
      'Summary below.'
    ],
    entryAriaPrefix: `On ${EntryAriaPrefixPlaceholder.Date} the following train operators will be affected:`,
    entries: [
      {
        date: 'Grand Central',
        body: 'There will be no Grand Central services.',
      },
      {
        date: 'Hull Trains',
        body: 'Running a reduced service which will divert to and run from London St Pancras International.',
      },
      {
        date: 'LNER',
        body: 'Operating a reduced service, starting and terminating at St Neots or Peterborough. Rail replacement service will run between St Neots and Bedford, where customers can rejoin train services to London St Pancras.',
      },
      {
        date: 'Lumo',
        body: 'Offering a reduced rail replacement service.',
      },
      {
        date: 'Great Northern and Thameslink',
        body: 'Various service disruptions across the whole timeframe. Rail replacement services will be running. Please check journey details for more information.',
      },
    ],
  }
]

const numberOfCurrentActionHeadings = currentAction.reduce(
  (accumulator, currentValue) => (!!currentValue.title ? accumulator + 1 : accumulator),
  0
)
const totalHeadings = numberOfCurrentActionHeadings + 2 // 2 other headings are help footer block AND either the refund tickets block or nothing to see here block.
const refundHeadingTitle = 'Refunding your tickets'
const refundHeadingAriaLabel = `${refundHeadingTitle}. Heading ${numberOfCurrentActionHeadings + 1} of ${totalHeadings}.`
const nothingToSeeHereHeadingAriaLabel = `Nothing to see here. Heading 1 of ${totalHeadings}.`
const helpFooterHeadingAriaSuffix = `Heading ${totalHeadings} of ${totalHeadings}.`

const getEntryAriaLabel = (
  item: StrikeEntry,
  index: number,
  hasOverrideEntriesAriaBlock: boolean,
  entryAriaPrefix: string | undefined,
  totalNumberOfEntries: number | undefined
) => {
  if (hasOverrideEntriesAriaBlock || !entryAriaPrefix || !totalNumberOfEntries) {
    return undefined
  }

  const prefix = entryAriaPrefix
    .replace(EntryAriaPrefixPlaceholder.Index, String(index + 1))
    .replace(EntryAriaPrefixPlaceholder.Total, String(totalNumberOfEntries))
    .replace(EntryAriaPrefixPlaceholder.Date, item.date)

  return `${prefix} ${item.body}`
}

export const IndustrialAction: FC = () => {
  const renderEntry = (
    item: StrikeEntry,
    index: number,
    hasOverrideEntriesAriaBlock: boolean,
    entryAriaPrefix: string | undefined,
    totalNumberOfEntries: number | undefined
  ) => {
    const infoboxAria = getEntryAriaLabel(item, index, hasOverrideEntriesAriaBlock, entryAriaPrefix, totalNumberOfEntries)
    return (
      <WarningInfobox
        aria-hidden={hasOverrideEntriesAriaBlock}
        role={hasOverrideEntriesAriaBlock ? undefined : 'text'}
        aria-label={infoboxAria}
        key={`${item.date}-${index}`}
        lastItem={index + 1 === totalNumberOfEntries}>
        <WarningAlertText aria-hidden={!!infoboxAria}>{String(item.date)}</WarningAlertText>
        {item.body && <WarningBodyText aria-hidden={!!infoboxAria}>{item.body}</WarningBodyText>}
      </WarningInfobox>
    )
  }

  return (
    <IndustrialActionContainer>
      <IndustrialActionSection>
        {currentAction.length > 0 ? (
          currentAction.map((item, currentActionIndex) => (
            <>
              {currentActionIndex !== 0 && (
                <DividerWrap>
                  <Divider top={0} />
                </DividerWrap>
              )}

              {item.title && (
                <IndustrialActionHeaderText
                  role="text"
                  aria-label={`${item.title}. Heading ${currentActionIndex + 1} of ${totalHeadings}`}
                  flushBottom={!!item.bodyTop?.length}>
                  {item.title}
                </IndustrialActionHeaderText>
              )}

              {item.bodyTop?.map((bodyItem, bodyIndex) => (
                <IndustrialActionParagraph aria-hidden={!!item.overrideEntriesAriaBlock} key={`${bodyItem}-${bodyIndex}`} flushTop={!!item.title}>
                  {bodyItem} {'\n\n'}
                </IndustrialActionParagraph>
              ))}

              {item.entries?.length && (
                <WarningInfoboxContainer
                  role={!!item.overrideEntriesAriaBlock ? 'text' : undefined}
                  aria-label={item.overrideEntriesAriaBlock}>
                  {item.entries?.map((entryItem, index) =>
                    renderEntry(entryItem, index, !!item.overrideEntriesAriaBlock, item.entryAriaPrefix, item.entries?.length)
                  )}
                </WarningInfoboxContainer>
              )}

              {item.bodyBottom?.map((bodyItem) => (
                <IndustrialActionParagraph>{bodyItem}</IndustrialActionParagraph>
              ))}
            </>
          ))
        ) : (
          <NothingToSeeContainer>
            <NothingToSeeIcon src={noTrainIcon} />
            <NothingToSeeHeader aria-label={nothingToSeeHereHeadingAriaLabel}>Nothing to see here…</NothingToSeeHeader>
            <NothingToSeeParagraph>
              There may be an error with the content of this page — for up to date information on disruptions visit the{' '}
              <NothingToSeeLink target={'_blank'} href={'https://www.nationalrail.co.uk/travel-information/industrial-action/'}>
                National Rail website.
              </NothingToSeeLink>
            </NothingToSeeParagraph>
          </NothingToSeeContainer>
        )}
      </IndustrialActionSection>
      {currentAction.length > 0 && (
        <IndustrialActionSection>
          <RefundTicketsContainer>
            <RefundTicketsTitle aria-label={refundHeadingAriaLabel}>{refundHeadingTitle}</RefundTicketsTitle>
            <RefundTicketsBody>
              If you booked your ticket with us and your service is now cancelled due to the above scheduled works, you can get a
              full refund with the online refund form. Make sure to select the "Service Cancelled/Disrupted" option.
              {'\n\n'}
              If your service is not affected by the disruptions, but your plans have changed, you can cancel your booking. However, you may need to pay a cancellation fee.
            </RefundTicketsBody>
            <RefundTicketsButton role="button" aria-label="Complete refund form" href={PATHS.TRAINS_REFUND_URL} target="_blank" rel="noopener noreferrer">
              Complete refund form
            </RefundTicketsButton>
          </RefundTicketsContainer>
        </IndustrialActionSection>
      )}
      <HelpFooterBlock ariaLabelSuffix={helpFooterHeadingAriaSuffix} />
    </IndustrialActionContainer>
  )
}
