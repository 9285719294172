import {getStoredFRTParameters} from "./storedFRTParameters";


const FRT_CAMPAIGN_PARAMETER = "utm_campaign"
const FRT_SOURCE_PARAMETER = "utm_source"
const FRT_MEDIUM_PARAMETER = "utm_medium"
const FRT_VTTID_PARAMETER = "vtt-id"
const FRT_REDID_PARAMETER = "red-id"
export const getFRTParameters = (defaultCampaignId: string | undefined, defaultSourceId: string | undefined, defaultMediumId: string | undefined) => {
    const storedParams = getStoredFRTParameters()
    const campaign = storedParams?.get("utm_campaign") ?? defaultCampaignId
    const source = storedParams?.get("utm_source") ?? defaultSourceId
    const medium = storedParams?.get("utm_medium") ?? defaultMediumId
    const vttId = storedParams?.get("vtt-id")
    const redId = storedParams?.get("red-id")

    const queryParameters = []
    if (campaign) queryParameters.push(`${FRT_CAMPAIGN_PARAMETER}=${campaign}`)
    if (source) queryParameters.push(`${FRT_SOURCE_PARAMETER}=${source}`)
    if (medium) queryParameters.push(`${FRT_MEDIUM_PARAMETER}=${medium}`)
    if (vttId) queryParameters.push(`${FRT_VTTID_PARAMETER}=${vttId}`)
    if (redId) queryParameters.push(`${FRT_REDID_PARAMETER}=${redId}`)

    return queryParameters.join("&")
}
