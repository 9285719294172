import {
  Body,
  CardCol,
  CardRow,
  CTAButton,
  Image,
  NotAvailableCardRow,
  NotAvailableTitle,
  Outer,
  RailcardCard,
  RailcardCardDisabled,
  RailcardPill,
  RailcardTitle,
  SubTitle,
  Title,
} from './FindingTheRightCard.styles'
import { Col, Container, Row } from 'react-bootstrap'
import { colors, Typography } from '../../../style'
import skater from '../../../img/illustration-skater.png'
import { generatePurchaseRailcardURL } from '../../../util/helpers/generatePurchaseRailcardURL'

export const FindingTheRightCard = () => {
  const purchaseRailcardURL = generatePurchaseRailcardURL('VTTWebsite', 'VTTWebsite-FindingTheRightCardSection', 'VTTWebsite')
  return (
    <Outer>
      <Container>
        <Row>
          <Col lg={{ span: 6, offset: 1 }}>
            <Title>Finding the right digital Railcard for you</Title>
            <SubTitle>
              Digital Railcards are just £30 for a year and will give you 1/3 off the cost of any eligible tickets for that whole year.
            </SubTitle>
            <Body>
              There are nine main Railcards in the UK, so whether you love to travel with friends and family, have a certain train buddy who
              you travel with often, are a student, travel around the South East or are simply lucky enough to be within a certain age
              bracket, there's likely a Railcard option that can help you save on eligible train tickets.
            </Body>
          </Col>
          <Col lg={4} className="d-none d-lg-block">
            <Image src={skater} />
          </Col>
        </Row>
        <CardRow>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>16-25 Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#eb7400" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>26-30 Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#eb4d00" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>Senior Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#100959" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>Two Together Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#71217f" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>Network Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#1077d1" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>Friends & Family Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#e3032d" />
            </RailcardCard>
          </CardCol>
          <CardCol>
            <RailcardCard>
              <RailcardTitle>16-17 Saver Railcard</RailcardTitle>
              <RailcardPill backgroundColor="#ffe802" />
            </RailcardCard>
          </CardCol>
        </CardRow>
        <Row>
          <Col className="d-flex justify-content-center">
            <CTAButton href={purchaseRailcardURL}>Read more and buy today</CTAButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <NotAvailableTitle>Railcards not currently available through Virgin Trains Ticketing:</NotAvailableTitle>
          </Col>
        </Row>
        <NotAvailableCardRow>
          <CardCol>
            <RailcardCardDisabled>
              <RailcardTitle>Disabled Persons Railcard</RailcardTitle>
              <RailcardPill backgroundColor={colors.TERTIART_CHARCOAL_80} />
            </RailcardCardDisabled>
          </CardCol>
          <CardCol>
            <RailcardCardDisabled>
              <RailcardTitle>Veterans Railcard</RailcardTitle>
              <RailcardPill backgroundColor={colors.TERTIART_CHARCOAL_80} />
            </RailcardCardDisabled>
          </CardCol>
        </NotAvailableCardRow>
        <Row>
          <Col lg={6}>
            <Typography.Paragraph className="m-0">
              These Railcards are available directly through National Rail. <br />
              <br />
              {/* eslint-disable-next-line react/jsx-pascal-case */}
              <Typography.anchorStyle href="/terms-conditions">For all Railcard terms see here</Typography.anchorStyle>
            </Typography.Paragraph>
          </Col>
        </Row>
      </Container>
    </Outer>
  )
}
