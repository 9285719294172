import { Col, Container, Row } from 'react-bootstrap'
import { Breadcrumb } from '../../../components/Breadcrumb/Breadcrumb'
import {
  CTAButton,
  SavingButton,
  Tooltip,
  WhyUsCaption,
  WhyUsCol,
  WhyUsImage,
  WhyUsSection,
  WhyUsText,
  WhyUsTextEmphasised,
  WhyUsTitle,
} from './WhyUs.styles'
import railcardVirginPoints from '../../../img/graphic-railcard-virgin-points.svg'
import railcardOfficialRetailer from '../../../img/graphic-railcard-official-retailer.svg'
import railcardSavings from '../../../img/graphic-railcard-savings.svg'
import railcardDigitalCard from '../../../img/graphic-railcard-digital-card.svg'
import React, { useState } from 'react'
import { shift, useFloating, useFocus, useHover, useInteractions } from '@floating-ui/react'
import { generatePurchaseRailcardURL } from '../../../util/helpers/generatePurchaseRailcardURL'

export const WhyUs = () => {
  const [isSavingTooltipOpen, setIsSavingTooltipOpen] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    middleware: [shift()],
    open: isSavingTooltipOpen,
    onOpenChange: setIsSavingTooltipOpen,
  })
  const hover = useHover(context)
  const focus = useFocus(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus])
  const purchaseRailcardURL = generatePurchaseRailcardURL('VTTWebsite', 'VTTWebsite-WhyUsSection', 'VTTWebsite')

  return (
    <WhyUsSection>
      <Container>
        <Row>
          <Col>
            <Breadcrumb items={[{ text: 'Railcards', url: '' }]} />
          </Col>
        </Row>
        <Row>
          <Col>
            <WhyUsTitle>Why buy a Railcard with us?</WhyUsTitle>
          </Col>
        </Row>
        <Row>
          <WhyUsCol md={6} lg={3}>
            <WhyUsImage src={railcardVirginPoints} />
            <WhyUsCaption>Earn Virgin Points on your Railcard&nbsp;purchase.</WhyUsCaption>
            <WhyUsText>
              These can be used to unlock future savings on train travel or put towards a mountain of rewards with Virgin Red. A free Virgin
              Red membership is required.
            </WhyUsText>
            <WhyUsTextEmphasised>You won't find that perk elsewhere!</WhyUsTextEmphasised>
          </WhyUsCol>
          <WhyUsCol md={6} lg={3}>
            <WhyUsImage src={railcardOfficialRetailer} />
            <WhyUsCaption>We're an official retailer of Railcards by National Rail.</WhyUsCaption>
            <WhyUsText>
              So, it’s exactly the same Railcard, but buy it with us, and you'll start unlocking even more savings and rewards.
            </WhyUsText>
            <WhyUsTextEmphasised>Now that's smart!</WhyUsTextEmphasised>
          </WhyUsCol>
          <WhyUsCol md={6} lg={3}>
            <WhyUsImage src={railcardSavings} />
            <WhyUsCaption>Unlock cheaper train tickets by saving 1/3 on eligible journeys.</WhyUsCaption>
            <WhyUsText>
              With the average annual saving being{' '}
              <SavingButton variant="link" ref={refs.setReference} {...getReferenceProps()}>
                £146*,
              </SavingButton>{' '}
              your Railcard could pay for itself with just a few trips.
            </WhyUsText>
            {isSavingTooltipOpen && (
              <Tooltip ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                *£146 average annual saving based on the average savings made by combined Railcard holders from April 2022 to March 2023.
                Excludes the price of Railcard.
                <br />
                <br />
                Statistic from National Rail.
              </Tooltip>
            )}
            <WhyUsTextEmphasised>Now you're talking.</WhyUsTextEmphasised>
          </WhyUsCol>
          <WhyUsCol md={6} lg={3}>
            <WhyUsImage src={railcardDigitalCard} />
            <WhyUsCaption>Your digital Railcard will be stored in the Virgin Trains Ticketing app.</WhyUsCaption>
            <WhyUsText>So easy — it's always ready at your fingertips for easy access and cheaper train travel.</WhyUsText>
            <WhyUsTextEmphasised>Let's go!</WhyUsTextEmphasised>
          </WhyUsCol>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <CTAButton href={purchaseRailcardURL}>Buy your Railcard now</CTAButton>
          </Col>
        </Row>
      </Container>
    </WhyUsSection>
  )
}
