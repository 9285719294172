import { Accordion, AccordionBody, AccordionButton, AccordionItem, FooterText, Section, SubTitle, Title } from './FAQ.styles'
import { Col, Container, Row } from 'react-bootstrap'
import { Typography } from '../../../style'
import { CTAButton } from '../WhyUsSection/WhyUs.styles'
import React from 'react'

export const FAQ = () => (
  <Section>
    <Container>
      <Row>
        <Col lg={{ offset: 2, span: 8 }}>
          <Title>Frequently asked questions</Title>
          <SubTitle>Got a burning question about Railcards? Let's see if we can help</SubTitle>
          <Accordion flush defaultActiveKey={'1'}>
            <AccordionItem eventKey={'1'}>
              <AccordionButton>
                <Typography.Intro>What is a Railcard? And why should I get one?</Typography.Intro>
              </AccordionButton>
              <AccordionBody>
                <Typography.Paragraph>
                  A Railcard is a nifty way to save you 1/3 on train travel for either 1-year or 3-years (depending on which Railcard you
                  buy).
                </Typography.Paragraph>
                <Typography.Paragraph>
                  There are nine main Railcards to choose from (of which we sell seven) and each Railcard has its own criteria so take a
                  look at the Railcard options and see if there's one that is right for you.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  We sell digital Railcards, and once you buy one it'll live safely in your Virgin Trains Ticketing app ready for you to
                  start unlocking cheaper train tickets instantly.
                </Typography.Paragraph>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey={'2'}>
              <AccordionButton>
                <Typography.Intro>How much does a Railcard cost?</Typography.Intro>
              </AccordionButton>
              <AccordionBody>
                <Typography.Paragraph>
                  <Typography.Paragraph>
                    A 1-year Railcard is £30, and this will give you 1/3 off on all eligible train travel for the full year.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Some Railcards also offer a 3-year option which will cost you £70 (a £20 saving across the 3-year period vs. purchasing
                    1-year Railcards).
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    The Disabled Persons Railcard and the Veterans Railcard ar both £20 for 1-year, but these cannot currently be bought
                    through Virgin Trains Ticketing.
                  </Typography.Paragraph>
                </Typography.Paragraph>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey={'3'}>
              <AccordionButton>
                <Typography.Intro>How do I get my hands on a Railcard?</Typography.Intro>
              </AccordionButton>
              <AccordionBody>
                <Typography.Paragraph>
                  There are two ways to buy a digital Railcard, either through this website or through our app. Both options take about 10
                  minutes (depending on how fast your fingers are) and follow the same steps. The choice is yours!
                </Typography.Paragraph>
                <Typography.Paragraph>
                  To purchase through the Virgin Trains Ticketing app, open the app, navigate to Account &gt; Railcards and select ‘Purchase
                  a Railcard’.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  To buy through our website, simply tap one of the “Purchase Railcard” buttons on this page.
                </Typography.Paragraph>
                <Typography.Paragraph>Once you’ve decided, there’s just 3-simple steps to follow:</Typography.Paragraph>
                <Typography.OrderedList>
                  <li>Choose your Railcard</li>
                  <li>Share some details so we can set you up an account (you’ll need ID and a passport-style portrait for this part)</li>
                  <li>Pay for your Railcard</li>
                </Typography.OrderedList>
                <Typography.Paragraph>
                  Your digital Railcard will then be ready to add to the Virgin Trains Ticketing app so you can start saving right away.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  This bit is important:
                  <br />
                  We’ll send you an download code by email. Just copy the code and paste into the Railcards section of the Virgin Trains
                  Ticketing app (Account &gt; Railcards &gt; “Add your Railcard download code”).
                </Typography.Paragraph>
                <Typography.Paragraph>Then boom. You’re done and ready to flex some serious savings.</Typography.Paragraph>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey={'4'}>
              <AccordionButton>
                <Typography.Intro>How do I use a Railcard?</Typography.Intro>
              </AccordionButton>
              <AccordionBody>
                <Typography.Paragraph>
                  Once you've bought your digital Railcard with us then it will sit safely in the Virgin Trains Ticketing app so it's at
                  your fingertips whenever you need it.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  When you buy a train ticket through Virgin Trains Ticketing you have the option to add your Railcard, which will apply any
                  available discounts. Then, if you come across a ticket inspector on your journey, you can just whip out your phone, show
                  the Railcard in the Virgin Trains Ticketing app and be on your merry way.
                </Typography.Paragraph>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col lg={{ offset: 3, span: 6 }}>
          <FooterText>
            Need a little more steer? Take a look at our customer support hub which will help you answer everything including "How do I add
            my Railcard to Oyster?"
          </FooterText>
          <div className="d-flex justify-content-center">
            <CTAButton href="https://trains.virgin.com/">Visit our customer support hub</CTAButton>
          </div>
        </Col>
      </Row>
    </Container>
  </Section>
)
